<template>
    <div>
        <img src="../../public/img/Privacy.jpg" alt="">
    </div>
</template>

<script>
export default {
    name: 'GmNotive',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
img{
    width: 100%;
}
</style>